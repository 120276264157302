import styled from "@emotion/styled";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { StrapiObject } from "../components/StrapiObject";

import { Breakpoints } from "../resources/types/Breakpoints";
import { Colors } from "../resources/types/Colors";
import { StyledParallax } from "./ParentsAndChilds";
import monParcoursPath from "../resources/texts/monParcours.md";
import mesOrientationsPath from "../resources/texts/mesOrientations.md";
export const About: React.FC = () => {
  const [monParcoursText, setMonParcoursText] = useState<string>("");
  const [mesOrientationsText, setMesOrientationsText] = useState<string>("");
  useEffect(() => {
    fetch(monParcoursPath)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Network response was not ok: ${response.status}`);
        }
        return response.text();
      })
      .then((data) => {
        setMonParcoursText(data);
      })
      .catch((error) => {
        console.error("Error fetching Markdown content:", error);
      });

    fetch(mesOrientationsPath)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Network response was not ok: ${response.status}`);
        }
        return response.text();
      })
      .then((data) => {
        setMesOrientationsText(data);
      })
      .catch((error) => {
        console.error("Error fetching Markdown content:", error);
      });
  }, []);
  return (
    <StyledPage>
      <Helmet>
        <title>À propos - Psychologue Frédérique HIRN</title>
        <meta
          name="description"
          content="Psychologue clinicienne, je suis spécialisée enfants et accompagnement à la parentalité.
J’accompagne également les professionnels de ce secteur. Mon approche est intégrative."
        />
        <meta
          name="keywords"
          content="psychologue, enfants, petite enfance, parentalité, attachement"
        />
      </Helmet>
      <h1>À propos</h1>

      <StyledParallax
        bgImage={`/images/julia_taubitz_ki_Vci_IFK_3x_I_unsplash_27782432ff.jpg`}
        bgImageAlt="bannière"
        strength={-200}
      ></StyledParallax>

      <article>
        <StrapiObject data={{ title: "Mon parcours", text: monParcoursText }} />

        <StrapiObject
          data={{ title: "Mes orientations", text: mesOrientationsText }}
          background
          backgroundColor={Colors.green}
        />
      </article>
    </StyledPage>
  );
};

export const StyledPage = styled.article`
  z-index: 500;

  h1 {
    font-size: 2rem;
    text-align: center;
    padding: 10px;
    width: 50%;
    margin: 0 auto 90px;
    color: ${Colors.white};
    background-color: ${Colors.green};
    border-radius: 20px;

    position: relative;

    @media (max-width: ${Breakpoints.mobile - 1}px) {
      width: 80%;
      font-size: 1.6rem;
    }

    &::after {
      content: "";
      display: block;
      width: 150%;
      height: 2px;
      border-top: 3px dotted ${Colors.red};
      position: absolute;
      bottom: -45px;
      left: -25%;
    }
  }

  article {
    border-top: 3px dotted ${Colors.red};
    padding-top: 70px;

    max-width: 1200px;
    width: 85%;

    margin: 40px auto;
  }
`;
