import { useEffect, useState } from "react";
import { StrapiObject } from "../components/StrapiObject";
import { StyledPage } from "./About";
import { Parallax } from "react-parallax";
import styled from "@emotion/styled";
import { Colors } from "../resources/types/Colors";
import { Helmet } from "react-helmet";
import consultation from "../resources/texts/consultation.md";

export const ParentsAndChilds: React.FC = () => {
  const titre = "Parents & Enfants";

  const [consultationText, setCOnsultationText] = useState<string>("");
  // const [ateliersText, setAteliersText] = useState<string>("");

  useEffect(() => {
    fetch(consultation)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Network response was not ok: ${response.status}`);
        }
        return response.text();
      })
      .then((data) => {
        setCOnsultationText(data);
      })
      .catch((error) => {
        console.error("Error fetching Markdown content:", error);
      });
  }, []);
  return (
    <StyledPage>
      <Helmet>
        <title>{titre} - Psychologue Frédérique HIRN</title>
        <meta
          name="description"
          content="Psychologue, je propose des consultations :suivis enfants/parents ou enfants/parents, ainsi que des ateliers parents pour mieux comprendre le développement de l’enfant, et ainsi mieux l’accompagner. Possibilité de prise en charge par le dispositif monpsy."
        />
        <meta
          name="keywords"
          content="monpsy, mon psy, remboursement, remboursé, sécurité sociale, prise en charge, psychologue, enfants, petite enfance, parentalité, attachement, consultation, suivi psychologique, ateliers, monpsy"
        />
      </Helmet>
      <h1>{titre}</h1>

      <StyledParallax
        bgImage={`/images/ben_wicks_i_D_Ctsz_INHI_unsplash_0ca537f562.jpg`}
        bgImageAlt="bannière"
        strength={-200}
      ></StyledParallax>

      <article>
        <StrapiObject
          data={{
            title: "Consultations parents, enfants, ou parents-enfants",
            text: consultationText,
          }}
        />

        {/* <StrapiObject data={ateliers} /> */}
      </article>
    </StyledPage>
  );
};

export const StyledParallax = styled(Parallax)`
  height: 300px;
  width: 100%;

  background-color: ${Colors.red};

  img {
    filter: grayscale(100%) contrast(110%) !important;
    mix-blend-mode: screen;
  }
`;
