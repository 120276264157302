import styled from "@emotion/styled";
import { Colors } from "../resources/types/Colors";
import { StyledPage } from "./About";
import { StyledParallax } from "./ParentsAndChilds";

export const Terms: React.FC = () => {
  const StyledArticle = styled.article`
    h2 {
      font-size: 1.5rem;
      margin: 1rem 0;
    }

    p {
      font-size: 1.1rem;
      margin: 1rem 0;
    }

    h3 {
      font-size: 1.3rem;
      margin: 1rem 0;
    }

    a {
      color: ${Colors.red};
      text-decoration: none;
    }
  `;
  return (
    <StyledPage>
      <StyledParallax
        bgImage={`/images/julia_taubitz_ki_Vci_IFK_3x_I_unsplash_27782432ff.jpg`}
        bgImageAlt="bannière"
        strength={-200}
      ></StyledParallax>
      <StyledArticle>
        <h2>Mentions légales (21-11-2022)</h2>
        <p>
          Conformément aux dispositions des Articles 6-III et 19 de la Loi
          n°2004-575 du 21 juin 2004 pour la Confiance dans l’économie
          numérique, dite L.C.E.N., il est porté à la connaissance des
          utilisateurs et visiteurs, ci-après l'"Utilisateur", du site
          frederique-hirn.com , ci-après le "Site", les présentes mentions
          légales. La connexion et la navigation sur le Site par l’Utilisateur
          implique acceptation intégrale et sans réserve des présentes mentions
          légales. Ces dernières sont accessibles sur le Site à la rubrique «
          Mentions légales ».
        </p>
        <h3>ARTICLE 1 : L’éditeur</h3>
        <p>
          L’édition et la direction de la publication du Site est assurée par
          Frédérique Hirn, domiciliée 83140 Six fours les plages, dont l'adresse
          e-mail{" "}
          <a href="mailto:contact@frederique-hirn.com">
            contact@frederique-hirn.com
          </a>
          . ci-après l'"Editeur".
        </p>
        <h3>ARTICLE 2 : L’hébergeur</h3>
        <p>
          L'hébergeur du Site est la société OVH, dont le siège social est situé
          au 2 rue Kellermann 59100 Roubaix 1007.
        </p>
        <h3>ARTICLE 3 : Accès au Site</h3>
        <p>
          Le Site est accessible en tout endroit, 7j/7, 24h/24 sauf cas de force
          majeure, interruption programmée ou non et pouvant découlant d’une
          nécessité de maintenance. En cas de modification, interruption ou
          suspension du Site, l'Editeur ne saurait être tenu responsable.
        </p>
        <h3>ARTICLE 4 : Collecte des données</h3>
        <p>
          Le site est exempté de déclaration à la Commission Nationale
          Informatique et Libertés (CNIL) dans la mesure où il ne collecte
          aucune donnée concernant les utilisateurs. Toute utilisation,
          reproduction, diffusion, commercialisation, modification de toute ou
          partie du Site, sans autorisation de l’Editeur est prohibée et pourra
          entraînée des actions et poursuites judiciaires telles que notamment
          prévues par le Code de la propriété intellectuelle et le Code civil.
        </p>
        <h3>ARTICLE 5 : Image d'illustrations</h3>
        <p>Image d'illustrations par peoplecreations sur Freepik</p>
      </StyledArticle>
    </StyledPage>
  );
};
