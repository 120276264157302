import styled from "@emotion/styled";
import { Link } from "react-router-dom";
import { Colors } from "../resources/types/Colors";
import { CustomLink } from "./UI/CustomLink";
import tinycolor from "tinycolor2";
import { Breakpoints } from "../resources/types/Breakpoints";

export const Footer: React.FC = () => {
  const StyledFooter = styled.footer`
    height: 100px;
    z-index: -1;

    background: linear-gradient(
      white,
      ${tinycolor(Colors.orange).setAlpha(0.5).toRgbString()}
    );

    ul {
      position: relative;
      top: 10px;
      display: flex;
      justify-content: center;
      gap: 80px;
      align-items: center;
      width: 100%;
      height: 100%;
    }

    @media (max-width: ${Breakpoints.mobile - 1}px) {
      &.home {
        position: fixed;
        width: 100%;
        bottom: 0px;
        left: 0;
        padding-bottom: 10px;
      }

      ul {
        gap: 18px;
        flex-direction: column !important;
        font-size: 11px;
      }
    }
  `;

  return (
    <StyledFooter>
      <ul>
        <li>
          <CustomLink to="/mentions-legales">Mentions Légales</CustomLink>
        </li>
        <li>Six-Fours-Les-Plages / La Seyne-Sur-Mer</li>
        <li>
          Édité par{" "}
          <a
            href="https://www.linkedin.com/in/hadrien-verrier/"
            target={"_blank"}
            rel={"noopener noreferrer"}
            style={{
              color: "black",
              textDecoration: "none",
              fontStyle: "italic",
            }}
          >
            VERRIER Hadrien
          </a>
        </li>
      </ul>
    </StyledFooter>
  );
};
