import { action, Action, thunk, Thunk } from "easy-peasy";
import { strapiObject } from "../types/entities/strapi-data";

export interface AboutModel {
  //state
  id: number;
  titre: string;
  monParcours: strapiObject;
  mesOrientations: strapiObject;
  // mesPublications: strapiObject;

  //setters
  setId: Action<AboutModel, number>;
  setTitre: Action<AboutModel, string>;
  setMonParcours: Action<AboutModel, strapiObject>;
  setMesOrientations: Action<AboutModel, strapiObject>;
  // setMesPublications: Action<AboutModel, strapiObject>;

  //thunk
  fetchAbout: Thunk<AboutModel>;
}

export const aboutStore = {
  id: 0,
  titre: "",
  monParcours: {
    title: "",
    text: "",
  },
  mesOrientations: {
    title: "",
    text: "",
  },
  // mesPublications: {
  //   title: "",
  //   text: "",
  // },

  setId: action<AboutModel, number>((state, payload) => {
    state.id = payload;
  }),
  setTitre: action<AboutModel, string>((state, payload) => {
    state.titre = payload;
  }),
  setMonParcours: action<AboutModel, strapiObject>((state, payload) => {
    state.monParcours = payload;
  }),
  setMesOrientations: action<AboutModel, strapiObject>((state, payload) => {
    state.mesOrientations = payload;
  }),

  fetchAbout: thunk<AboutModel>(async (actions, payload) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/a-propos`);

    if (response.ok) {
      const data = await response.json();
      actions.setId(data.id);
      actions.setTitre(data.titre);

      actions.setMonParcours({
        title: data.monParcoursTitre,
        text: data.monParcoursTexte,
      });
      actions.setMesOrientations({
        title: data.mesOrientationsTitre,
        text: data.mesOrientationsTexte,
      });
      // actions.setMesPublications({
      //   title: data.mesPublicationsTitre,
      //   text: data.mesPublicationsTexte,
      // });
    } else {
      console.log("error");
    }
  }),
};
