import styled from "@emotion/styled";
import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { removeHomeClass } from "../resources/hooks/removeHomeClass";
import { useWindowSize } from "../resources/hooks/windowSize";
import { Breakpoints } from "../resources/types/Breakpoints";
import { Colors } from "../resources/types/Colors";
import { NavLink } from "./UI/NavLink";

export const Navigation: React.FC = () => {
  const location = useLocation();

  const size = useWindowSize();
  const [ulWidth, setUlWidth] = useState("125px");

  const nav = useRef<HTMLElement>(null);
  const button = useRef<HTMLButtonElement>(null);

  const elmArray = [nav, button];

  useEffect(() => {
    removeHomeClass(elmArray, location.pathname);
  });

  const [navOpen, setNavOpen] = useState(false);
  const toggleNav = () => {
    setNavOpen(!navOpen);
  };

  useEffect(() => {
    if (size.width && size.width < 700) {
      setUlWidth("100px");
    }
    if (size.width && size.width >= 700) {
      setUlWidth("125px");
    }
  }, [size.width]);

  const StyledNavigation = styled.nav`
    z-index: 2;

    ul {
      li {
        cursor: pointer;
        text-align: center;
      }
    }

    &:not(.home) {
      margin-top: 80px;

      @media (max-width: ${Breakpoints.tablet - 1}px) {
        margin: 20px auto;
        li {
          font-size: 18px !important;
        }
      }

      @media (max-width: ${Breakpoints.mobile - 1}px) {
        display: ${navOpen ? "block" : "none"};
        margin-top: 0;
        margin-bottom: 0;
        padding: 0;
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background-color: rgba(255, 255, 255, 0.95);

        z-index: 200;
        ul {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          height: 100%;
          width: 100%;

          li {
            font-size: 1.8em !important;
            font-weight: bolder;
            margin: 0;
            padding: 0;
            margin-bottom: 20px;
          }
        }
      }

      ul {
        gap: 25px;
        justify-content: center;
        display: flex;
        li {
          font-size: 1.3em;
          font-weight: 600;
        }
        br {
          display: none;
        }
      }
    }

    &.home {
      position: absolute;
      top: 50px;
      left: 0;
      width: 100%;
      height: calc(100% - 100px);

      ul {
        aspect-ratio: 1/1;
        outline: 8px dotted ${Colors.lightOrange};
        border-radius: 50%;

        width: calc(50% + ${ulWidth});
        max-width: calc(350px + ${ulWidth});
        min-width: calc(200px + ${ulWidth});

        list-style: none;

        position: relative;
        top: 55%;
        left: 50%;
        transform: translate(-50%, -50%);

        //place all li in circle around center
        li {
          font-size: 1.5em;
          font-weight: 600;

          position: absolute;
          display: block;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);

          &:nth-of-type(1) {
            top: 20%;
            left: 8%;
          }
          &:nth-of-type(2) {
            top: 20%;
            left: 95%;
          }
          &:nth-of-type(3) {
            top: 75%;
            left: 8%;
          }
          &:nth-of-type(4) {
            top: 75%;
            left: 95%;
          }
        }
      }

      @media (max-width: ${Breakpoints.mobile - 1}px) {
        ul {
          width: calc(33% + ${ulWidth});
          min-width: unset;
          outline: 4px dotted ${Colors.lightOrange};

          li {
            font-size: 1em;
            font-weight: 600;
          }
        }
      }
    }
  `;

  const StyledButton = styled.button`
    &:not(.home) {
      @media (max-width: ${Breakpoints.mobile - 1}px) {
        display: block;
        position: absolute;
        top: 25px;
        right: 30px;

        width: 65px;
        height: 65px;
        z-index: 300;
        background-color: transparent;
        border: none;
        cursor: pointer;
      }
    }

    display: none;
  `;

  return (
    <>
      <StyledButton onClick={toggleNav} ref={button}>
        <svg
          clip-rule="evenodd"
          fill-rule="evenodd"
          stroke-linejoin="round"
          stroke-miterlimit="2"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="m22 16.75c0-.414-.336-.75-.75-.75h-18.5c-.414 0-.75.336-.75.75s.336.75.75.75h18.5c.414 0 .75-.336.75-.75zm0-5c0-.414-.336-.75-.75-.75h-18.5c-.414 0-.75.336-.75.75s.336.75.75.75h18.5c.414 0 .75-.336.75-.75zm0-5c0-.414-.336-.75-.75-.75h-18.5c-.414 0-.75.336-.75.75s.336.75.75.75h18.5c.414 0 .75-.336.75-.75z"
            fill-rule="nonzero"
          />
        </svg>
      </StyledButton>
      <StyledNavigation ref={nav}>
        <ul>
          <li>
            <NavLink to="/parents-et-enfants">
              Parents
              <br /> & <br />
              Enfants
            </NavLink>
          </li>
          <li>
            <NavLink to="/professionnels">Professionnels</NavLink>
          </li>
          <li>
            <NavLink to="/a-propos">À propos</NavLink>
          </li>
          <li>
            <NavLink to="/acces-et-contact">Accès & Contact</NavLink>
          </li>
        </ul>
      </StyledNavigation>
    </>
  );
};
