import { action, Action, thunk, Thunk } from "easy-peasy";
import { strapiObject } from "../types/entities/strapi-data";

export interface AccesAndContactModel {
  //state
  id: number;
  titre: string;
  lieuDeConsultation: strapiObject;
  adresse: strapiObject;
  telephone: strapiObject;
  mail: strapiObject;

  //setters
  setId: Action<AccesAndContactModel, number>;
  setTitre: Action<AccesAndContactModel, string>;
  setLieuDeConsultation: Action<AccesAndContactModel, strapiObject>;
  setAdresse: Action<AccesAndContactModel, strapiObject>;
  setTelephone: Action<AccesAndContactModel, strapiObject>;
  setMail: Action<AccesAndContactModel, strapiObject>;

  //thunk
  fetchAccesAndContact: Thunk<AccesAndContactModel>;
}

export const accesAndContactStore = {
  id: 0,
  titre: "",
  lieuDeConsultation: {
    title: "",
    text: "",
  },
  adresse: {
    title: "",
    text: "",
  },
  telephone: {
    title: "",
    text: "",
  },
  mail: {
    title: "",
    text: "",
  },

  setId: action<AccesAndContactModel, number>((state, payload) => {
    state.id = payload;
  }),
  setTitre: action<AccesAndContactModel, string>((state, payload) => {
    state.titre = payload;
  }),
  setLieuDeConsultation: action<AccesAndContactModel, strapiObject>(
    (state, payload) => {
      state.lieuDeConsultation = payload;
    }
  ),
  setAdresse: action<AccesAndContactModel, strapiObject>((state, payload) => {
    state.adresse = payload;
  }),
  setTelephone: action<AccesAndContactModel, strapiObject>((state, payload) => {
    state.telephone = payload;
  }),
  setMail: action<AccesAndContactModel, strapiObject>((state, payload) => {
    state.mail = payload;
  }),

  fetchAccesAndContact: thunk<AccesAndContactModel>(
    async (actions, payload) => {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/acces-et-contact`
      );

      if (response.ok) {
        const data = await response.json();
        actions.setId(data.id);
        actions.setTitre(data.titre);
        actions.setLieuDeConsultation({
          title: data.LieuDeConsultationTitre,
          text: data.LieuDeConsultationTexte,
        });
        actions.setAdresse({
          title: data.AdresseTitre,
          text: data.AdresseTexte,
        });
        actions.setTelephone({
          title: data.TelephoneTitre,
          text: data.TelephoneTexte,
        });
        actions.setMail({
          title: data.MailTitre,
          text: data.MailTexte,
        });
      } else {
        console.log("error");
      }
    }
  ),
};
