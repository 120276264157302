import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { StrapiObject } from "../components/StrapiObject";
import { Colors } from "../resources/types/Colors";
import { StyledPage } from "./About";
import { StyledParallax } from "./ParentsAndChilds";
import analyse from "../resources/texts/analyse.md";
import experience from "../resources/texts/experience.md";
import publication from "../resources/texts/publication.md";
export const Professionnals: React.FC = () => {
  const titre = "Professionnels";
  const [analyseText, setAnalyseText] = useState<string>("");
  const [experienceText, setExperienceText] = useState<string>("");
  const [publicationText, setPublicationText] = useState<string>("");

  useEffect(() => {
    fetch(analyse)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Network response was not ok: ${response.status}`);
        }
        return response.text();
      })

      .then((data) => {
        setAnalyseText(data);
      })

      .catch((error) => {
        console.error("Error fetching Markdown content:", error);
      });

    fetch(experience)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Network response was not ok: ${response.status}`);
        }
        return response.text();
      })
      .then((data) => {
        setExperienceText(data);
      })

      .catch((error) => {
        console.error("Error fetching Markdown content:", error);
      });

    fetch(publication)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Network response was not ok: ${response.status}`);
        }
        return response.text();
      })
      .then((data) => {
        setPublicationText(data);
      })
      .catch((error) => {
        console.error("Error fetching Markdown content:", error);
      });
  }, []);
  return (
    <StyledPage>
      <Helmet>
        <title>{titre} - Psychologue Frédérique HIRN</title>
        <meta
          name="description"
          content="Je propose de l’analyse de pratique pour les professionnels de la petite enfance, de l’enfance et du travail social. Je peux proposer également de l’aide à la régulation d’équipe"
        />
        <meta
          name="keywords"
          content="analyse de pratique, professionnels, enfance, travail social, crèche, équipe, directrices, psychologue"
        />
      </Helmet>
      <h1>{titre}</h1>

      <StyledParallax
        bgImage={`/images/phil_hearing_cyl_PETXS_7is_unsplash_11ca6f7ba2.jpg`}
        bgImageAlt="bannière"
        strength={-200}
      ></StyledParallax>

      <article>
        <StrapiObject
          data={{
            title: "Analyse de pratique professionnelle",
            text: analyseText,
          }}
          background
          backgroundColor={Colors.green}
        />

        <StrapiObject data={{ title: "Expérience", text: experienceText }} />

        <StrapiObject data={{ title: "Publications", text: publicationText }} />
      </article>
    </StyledPage>
  );
};
