import styled from "@emotion/styled";
import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { removeHomeClass } from "../../resources/hooks/removeHomeClass";
import bulle from "../../resources/images/bulle.svg";
import { Breakpoints } from "../../resources/types/Breakpoints";

interface IBulletProps {
  bulletColor: string;
  ringColor: string;
  x: string;
  y: string;
  size?: string;
  ringPl?: "tl" | "tr" | "bl" | "br";
  ringOnly?: boolean;
}

export const Bullet: React.FC<IBulletProps> = ({
  bulletColor,
  ringColor,
  x,
  y,
  size = "200px",
  ringPl = "tl",
  ringOnly = false,
}) => {
  const location = useLocation();

  //generate unique hash for each bullet
  const hash =
    Math.random().toString(36).substring(2, 15) +
    Math.random().toString(36).substring(2, 15);

  const bullet = useRef<HTMLDivElement>(null);

  const arrayElm = [bullet];

  useEffect(() => {
    removeHomeClass(arrayElm, location.pathname);
  }, [location.pathname]);

  //generate value bewteen 2 and 7 seconds
  const randomTime = Math.floor(Math.random() * 8 + 2);

  const ringPlacement = useRef(
    "top: 20%;left: 15%;transform: translate(-50%, -50%) rotate(35deg);"
  );

  useEffect(() => {
    if (ringPl === "tl") {
      ringPlacement.current =
        "top: 20%;left: 15%;transform: translate(-50%, -50%) rotate(35deg);";
    }
    if (ringPl === "tr") {
      ringPlacement.current =
        "top: 20%;left: 85%;transform: translate(-50%, -50%) rotate(125deg);";
    }
    if (ringPl === "bl") {
      ringPlacement.current =
        "top: 82%;left: 15%;transform: translate(-50%, -50%) rotate(-35deg);";
    }
    if (ringPl === "br") {
      ringPlacement.current =
        "top: 82%;left: 85%;transform: translate(-50%, -50%) rotate(-125deg);";
    }
  }, [ringPl]);

  const StyledRing = styled.div`
    position: absolute;
    box-shadow: 0 0 1px rgba(0, 0, 0, 0.7);
    fill: ${ringColor};
    mask: url(${bulle}) no-repeat center;
    mask-size: cover;
    background-color: ${ringColor};
    z-index: 0;

    width: calc(90%);
    height: calc(90%);

    ${ringPlacement.current}
  `;

  const StyledCircle = styled.div`
    box-shadow: 0 0 1px rgba(0, 0, 0, 0.7);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: ${bulletColor};
    border-radius: 50%;

    width: 100%;
    height: 100%;
  `;

  const StyledContainer = styled.div`
    z-index: -1;
    user-select: none;
    pointer-events: none;
    width: ${size};
    height: ${size};
    position: fixed;
    display: block;
    top: ${y};
    left: ${x};

    //if not home page
    &:not(.home) {
      opacity: 0.3;
    }

    @media (max-width: ${Breakpoints.mobile - 1}px) {
      opacity: 0.3;
    }
    transform: translate(-50%, -50%);

    animation: ${"slowFloat" + hash} ${randomTime + "s"} linear infinite;

    @keyframes ${"slowFloat" + hash} {
      0% {
        top: ${y};
        left: ${x};
        transform: translate3d(-50%, -50%, 0px) rotate3d(0deg);
      }

      50% {
        top: calc(${y} - 10px);
        left: calc(${x} - 10px);
        transform: translate3d(-50%, -50%, 0px) rotate3d(0.2deg);
      }

      100% {
        top: ${y};
        left: ${x};
        transform: translate3d(-50%, -50%, 0px) rotate3d(0deg);
      }
    }
  `;
  return (
    <StyledContainer ref={bullet}>
      {!ringOnly && <StyledCircle />}
      <StyledRing />
    </StyledContainer>
  );
};
