import { StoreProvider } from "easy-peasy";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Layout } from "./components/UI/Layout";
import { About } from "./pages/About";
import { AccesAndContact } from "./pages/AccesAndContact";
import { Home } from "./pages/Home";
import { ParentsAndChilds } from "./pages/ParentsAndChilds";
import { Professionnals } from "./pages/Professionnals";
import { Terms } from "./pages/Terms";
import { store } from "./resources/store/store";
export const App: React.FC = () => {
  return (
    <StoreProvider store={store}>
      <Router>
        <Layout>
          <Routes>
            <Route path="/" element={<Home />}></Route>
            <Route
              path="parents-et-enfants"
              element={<ParentsAndChilds />}
            ></Route>

            <Route path="professionnels" element={<Professionnals />}></Route>

            <Route path="/a-propos" element={<About />}></Route>

            <Route
              path="/acces-et-contact"
              element={<AccesAndContact />}
            ></Route>

            <Route path="/mentions-legales" element={<Terms />}></Route>
          </Routes>
        </Layout>
      </Router>
    </StoreProvider>
  );
};
