export const removeHomeClass = (array: any[], location: string) => {
  if (location === "/") {
    array.forEach((elm) => {
      elm.current?.classList.add("home");
    });

    return;
  }

  if (location !== "/") {
    array.forEach((elm) => {
      elm.current?.classList.remove("home");
    });

    return;
  }
};
