import styled from "@emotion/styled";
import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { removeHomeClass } from "../../resources/hooks/removeHomeClass";
import { Breakpoints } from "../../resources/types/Breakpoints";
import { Colors } from "../../resources/types/Colors";
import { Footer } from "../Footer";
import { Header } from "../Header";
import { Bullet } from "./Bullet";

interface LayoutProps {
  children?: React.ReactNode;
}

export const Layout: React.FC<LayoutProps> = ({ children }) => {
  const location = useLocation();

  const main = useRef<HTMLElement>(null);

  const arrayElm = [main];

  useEffect(() => {
    removeHomeClass(arrayElm, location.pathname);
  }, [location.pathname]);

  const StyledMain = styled.main`
    overflow-x: hidden !important;
    width: 100vw;
    margin: 0 auto;
    line-height: 1.4;

    height: calc(100vh - 201px - 85px);

    @media (max-width: ${Breakpoints.tablet - 1}px) {
      height: calc(100vh - 241px - 85px);
    }

    @media (max-width: ${Breakpoints.mobile - 1}px) {
      height: calc(100vh - 201px - 85px);
    }

    &.home {
      height: unset;
    }

    &:not(.home) {
      width: 85%;
      margin-top: 85px;

      height: 100%;
    }
  `;

  return (
    <>
      <Header />
      <StyledMain ref={main}>
        {children}

        {/* LEFT SIDE */}
        <Bullet
          bulletColor={Colors.green}
          ringColor={Colors.red}
          x="15%"
          y="20%"
          ringPl="tl"
          ringOnly
        />

        <Bullet
          bulletColor={Colors.orange}
          ringColor={Colors.green}
          x="28%"
          y="45%"
          ringPl="bl"
          size="120px"
        />

        <Bullet
          bulletColor={Colors.green}
          ringColor={Colors.orange}
          x="15%"
          y="78%"
          ringPl="tl"
        />

        {/* right SIDE */}
        <Bullet
          bulletColor={Colors.red}
          ringColor={Colors.green}
          x="85%"
          y="20%"
          ringPl="tr"
        />

        <Bullet
          bulletColor={Colors.orange}
          ringColor={Colors.orange}
          x="78%"
          y="55%"
          ringPl="bl"
          size="180px"
          ringOnly
        />

        <Bullet
          bulletColor={Colors.green}
          ringColor={Colors.orange}
          x="89%"
          y="78%"
          ringPl="tl"
          size="120px"
        />
      </StyledMain>
      <Footer />
    </>
  );
};
