import styled from "@emotion/styled";
import { Link, useMatch, useResolvedPath } from "react-router-dom";

export const NavLink = ({
  to,
  children,
}: {
  to: string;
  children: React.ReactNode;
}) => {
  let resolved = useResolvedPath(to);
  let match = useMatch({ path: resolved.pathname, end: true });

  return (
    <StyledLink to={to} className={match ? "active" : ""}>
      {children}
    </StyledLink>
  );
};

const StyledLink = styled(Link)`
  color: black;
  transition: color 0.2s ease-in-out;
  text-decoration: none;

  &.active {
    position: relative;
    &::before {
      content: "";
      position: absolute;
      bottom: -0.5px;
      left: 0;
      width: 100%;
      border: 2px;
      border-style: dotted none none none;
    }
  }
`;
