import styled from "@emotion/styled";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { ContactForm } from "../components/ContactForm";
import { StrapiObject } from "../components/StrapiObject";
import { Breakpoints } from "../resources/types/Breakpoints";
import { Colors } from "../resources/types/Colors";
import { StyledPage } from "./About";
import { StyledParallax } from "./ParentsAndChilds";
import lieuConsultation from "../resources/texts/lieuConsultation.md";
import adresse from "../resources/texts/adresse.md";
import telephone from "../resources/texts/telephone.md";
import mail from "../resources/texts/mail.md";

export const AccesAndContact: React.FC = () => {
  const titre = "Accès et contact";

  const [lieuDeConsultationText, setLieuDeConsultationText] =
    useState<string>("");
  const [adresseText, Text] = useState<string>("");
  const [telephoneText, setTelephoneText] = useState<string>("");
  const [mailText, setMailText] = useState<string>("");

  useEffect(() => {
    fetch(lieuConsultation)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Network response was not ok: ${response.status}`);
        }
        return response.text();
      })
      .then((data) => {
        setLieuDeConsultationText(data);
      })
      .catch((error) => {
        console.error("Error fetching Markdown content:", error);
      });

    fetch(adresse)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Network response was not ok: ${response.status}`);
        }
        return response.text();
      })
      .then((data) => {
        Text(data);
      })
      .catch((error) => {
        console.error("Error fetching Markdown content:", error);
      });

    fetch(telephone)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Network response was not ok: ${response.status}`);
        }
        return response.text();
      })
      .then((data) => {
        setTelephoneText(data);
      })
      .catch((error) => {
        console.error("Error fetching Markdown content:", error);
      });

    fetch(mail)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Network response was not ok: ${response.status}`);
        }
        return response.text();
      })
      .then((data) => {
        setMailText(data);
      })
      .catch((error) => {
        console.error("Error fetching Markdown content:", error);
      });
  }, []);
  const StyledGrid = styled.article`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: "30px";
    @media (max-width: ${Breakpoints.mobile - 1}px) {
      display: flex;
      flex-direction: column;
    }
  `;

  const StyledDoctolib = styled.p`
    text-align: center;
    font-size: 1.5rem;
    margin: -10px auto 50px;
    grid-column: 1/3;
    a {
      color: ${Colors.red};
      text-decoration: none;
      font-weight: 500;

      display: inline-block;
      margin: 0 5px;
      &::after {
        mask: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMjEgMTN2MTBoLTIxdi0xOWgxMnYyaC0xMHYxNWgxN3YtOGgyem0zLTEyaC0xMC45ODhsNC4wMzUgNC02Ljk3NyA3LjA3IDIuODI4IDIuODI4IDYuOTc3LTcuMDcgNC4xMjUgNC4xNzJ2LTExeiIvPjwvc3ZnPg==");
        content: " ";
        mask-size: contain;
        display: inline-block;
        position: relative;
        top: 2px;
        width: 15px;
        height: 15px;
        margin-left: 5px;
        background-color: ${Colors.red};
      }
    }
  `;

  return (
    <StyledPage>
      <Helmet>
        <title>{titre} - Psychologue Frédérique HIRN</title>
        <meta
          name="description"
          content="Mon cabinet se situe dans la zones des Playes, à la Seyne-sur-mer (à proximité de Six-Fours-Les-Plages, Sanary , Ollioules, Bandol, Toulon Ouest). Ouvert le samedi matin."
        />
        <meta
          name="keywords"
          content="La Seyne-sur-mer, six-fours-les plages, sanary, ollioules, bandol, toulon ouest, samedi, consultation, psychologue, contact"
        />
      </Helmet>
      <h1>{titre}</h1>

      <StyledParallax
        bgImage={`/images/nordwood_themes_q8_U1_Yg_Ba_R_Qk_unsplash_81f7d74f6f.jpg`}
        bgImageAlt="bannière"
        strength={-200}
      ></StyledParallax>

      <StyledGrid>
        <StrapiObject
          data={{
            title: "Horaires d'ouverture & Accès",
            text: lieuDeConsultationText,
          }}
        />

        <StrapiObject
          data={{ title: "Adresse", text: adresseText }}
          type="address"
        />

        <StrapiObject
          data={{ title: "Téléphone", text: telephoneText }}
          type="telephone"
        />

        <StrapiObject data={{ title: "Email", text: mailText }} />

        <StyledDoctolib>
          Prenez rendez-vous sur{" "}
          <a
            href="https://perfactive.fr/psychologue/six-fours-les-plages/frederique-hirn"
            target="_blank"
            rel="noopener noreferrer"
          >
            Perfactive
          </a>{" "}
          ou ci-dessous via le formulaire.
        </StyledDoctolib>
        <div style={{ gridColumn: "1/3" }}>
          <ContactForm />
        </div>
      </StyledGrid>
    </StyledPage>
  );
};
