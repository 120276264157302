import { strapiObject } from "../resources/types/entities/strapi-data";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import styled from "@emotion/styled";
import { Colors } from "../resources/types/Colors";
import { useEffect, useState } from "react";
import { Breakpoints } from "../resources/types/Breakpoints";

interface StrapiObjectProps {
  data: strapiObject;
  background?: boolean;
  backgroundColor?: string;
  type?: "regular" | "address" | "telephone";
}

export const StrapiObject: React.FC<StrapiObjectProps> = ({
  data,
  background = false,
  backgroundColor = "white",
  type = "regular",
}) => {
  const [arrangedData, setArrangedData] = useState<strapiObject>({
    title: "",
    text: "",
  });

  const StyledSection = styled.section`
    margin-bottom: 50px;

    z-index: 100;

    background-color: ${background ? backgroundColor : "transparent"};
    padding: ${background ? "50px" : "0"};
    @media (max-width: ${Breakpoints.mobile - 1}px) {
      padding: ${background ? "20px" : "0"};
    }
    color: ${background ? "white" : "black"};
    line-height: 1.5;
    font-size: 1.2rem;

    @media (max-width: ${Breakpoints.mobile - 1}px) {
      font-size: 1rem;
    }

    &::after {
      content: "";
      display: ${background ? "none" : "block"};
      width: 75%;
      margin: 10px auto;
      height: 1px;
      background-color: ${background ? Colors.gray : "transparent"};
    }
    h2 {
      margin-bottom: 30px;
      font-size: 1.6rem;
      font-weight: 500;
      @media (max-width: ${Breakpoints.mobile - 1}px) {
        font-size: 1.3rem;
      }
    }

    h3 {
      font-size: 1.4rem;
      font-weight: 500;
      width: 90%;
      margin: 40px auto 20px;
    }
    h4 {
      font-size: 1.3rem;
      font-weight: 400;
      width: 90%;
      margin: 0 auto 20px;
    }

    p {
      margin-bottom: 20px;
      width: 90%;
      margin: 20px auto;

      @media (max-width: ${Breakpoints.mobile - 1}px) {
        width: 100%;
      }
    }

    a {
      color: ${Colors.red};
      text-decoration: none;
      font-weight: 500;

      &::after {
        mask: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMjEgMTN2MTBoLTIxdi0xOWgxMnYyaC0xMHYxNWgxN3YtOGgyem0zLTEyaC0xMC45ODhsNC4wMzUgNC02Ljk3NyA3LjA3IDIuODI4IDIuODI4IDYuOTc3LTcuMDcgNC4xMjUgNC4xNzJ2LTExeiIvPjwvc3ZnPg==");
        content: " ";
        mask-size: contain;
        display: inline-block;
        position: relative;
        top: 2px;
        width: 15px;
        height: 15px;
        margin-left: 5px;
        background-color: ${Colors.red};
      }
    }

    ul {
      margin-left: 60px;
      @media (max-width: ${Breakpoints.mobile - 1}px) {
        margin-left: 30px;
      }
      list-style-type: disc;

      li {
        margin-bottom: 10px;
      }
    }

    strong {
      font-weight: 800;
    }

    img {
      display: block;
      margin: 20px auto 90px;
      width: 80%;
      max-width: 400px;
    }
  `;

  useEffect(() => {
    //replace all (/uploads/ by strapi url + /uploads/) if there is any
    if (
      data.title !== "" &&
      data.text !== "" &&
      data.title !== null &&
      data.text !== null
    ) {
      const regex = /\/uploads\//g;
      const str = data.text;
      const subst = `/images/`;
      const result = str.replace(regex, subst);
      setArrangedData({
        title: data.title,
        text: result,
      });
    }
  }, [data]);

  return (
    <StyledSection>
      <h2>{arrangedData.title}</h2>

      {type === "regular" && (
        <ReactMarkdown
          children={arrangedData.text}
          remarkPlugins={[remarkGfm]}
        ></ReactMarkdown>
      )}

      {type === "address" && (
        <p>
          <a
            href={`https://www.google.fr/maps/place/${encodeURI(
              data.text.replace(" ", "+")
            )}`}
            target="_blank"
            rel="noreferrer noopener"
          >
            {data.text}
          </a>
        </p>
      )}

      {type === "telephone" && (
        <p>
          <a href={`tel:${data.text}`}>{data.text}</a>
        </p>
      )}
    </StyledSection>
  );
};
