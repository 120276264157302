import { action, Action, thunk, Thunk } from "easy-peasy";
import { strapiObject } from "../types/entities/strapi-data";

export interface ParentsAndChildsModel {
  //state
  id: number;
  titre: string;
  consultation: strapiObject;
  ateliers: strapiObject;

  //setters
  setId: Action<ParentsAndChildsModel, number>;
  setTitre: Action<ParentsAndChildsModel, string>;
  setConsultation: Action<ParentsAndChildsModel, strapiObject>;
  setAteliers: Action<ParentsAndChildsModel, strapiObject>;

  //thunk
  fetchParentsAndChilds: Thunk<ParentsAndChildsModel>;
}

export const parentsAndChildsStore = {
  id: 0,
  titre: "",
  consultation: {
    title: "",
    text: "",
  },
  ateliers: {
    title: "",
    text: "",
  },

  setId: action<ParentsAndChildsModel, number>((state, payload) => {
    state.id = payload;
  }),
  setTitre: action<ParentsAndChildsModel, string>((state, payload) => {
    state.titre = payload;
  }),
  setConsultation: action<ParentsAndChildsModel, strapiObject>(
    (state, payload) => {
      state.consultation = payload;
    }
  ),
  setAteliers: action<ParentsAndChildsModel, strapiObject>((state, payload) => {
    state.ateliers = payload;
  }),

  fetchParentsAndChilds: thunk<ParentsAndChildsModel>(
    async (actions, payload) => {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/parents-and-enfants`
      );
      if (response.ok) {
        const data = await response.json();

        actions.setId(data.id);
        actions.setTitre(data.titre);

        actions.setConsultation({
          title: data.consultationTitre,
          text: data.consultationTexte,
        });
        actions.setAteliers({
          title: data.ateliersTitre,
          text: data.ateliersTexte,
        });
      } else {
        console.log("error");
      }
    }
  ),
};
