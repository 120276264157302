import { action, Action, thunk, Thunk } from "easy-peasy";
import { strapiObject } from "../types/entities/strapi-data";

export interface ProfessionalsModel {
  //state
  id: number;
  titre: string;
  analyseDePratique: strapiObject;
  experience: strapiObject;
  publications: strapiObject;

  //setters
  setId: Action<ProfessionalsModel, number>;
  setTitre: Action<ProfessionalsModel, string>;
  setAnalyseDePratique: Action<ProfessionalsModel, strapiObject>;
  setExperience: Action<ProfessionalsModel, strapiObject>;
  setPublications: Action<ProfessionalsModel, strapiObject>;

  //thunk
  fetchProfessionals: Thunk<ProfessionalsModel>;
}

export const professionalsStore = {
  id: 0,
  titre: "",
  analyseDePratique: {
    title: "",
    text: "",
  },
  experience: {
    title: "",
    text: "",
  },
  publications: {
    title: "",
    text: "",
  },

  setId: action<ProfessionalsModel, number>((state, payload) => {
    state.id = payload;
  }),
  setTitre: action<ProfessionalsModel, string>((state, payload) => {
    state.titre = payload;
  }),
  setAnalyseDePratique: action<ProfessionalsModel, strapiObject>(
    (state, payload) => {
      state.analyseDePratique = payload;
    }
  ),
  setExperience: action<ProfessionalsModel, strapiObject>((state, payload) => {
    state.experience = payload;
  }),
  setPublications: action<ProfessionalsModel, strapiObject>(
    (state, payload) => {
      state.publications = payload;
    }
  ),

  fetchProfessionals: thunk<ProfessionalsModel>(async (actions, payload) => {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/professionnels`
    );

    if (response.ok) {
      const data = await response.json();
      actions.setId(data.id);
      actions.setTitre(data.titre);
      actions.setAnalyseDePratique({
        title: data.analyseDePratiqueTitre,
        text: data.analyseDePratiqueTexte,
      });
      actions.setExperience({
        title: data.experienceTitre,
        text: data.experienceTexte,
      });
      actions.setPublications({
        title: data.publicationTitre,
        text: data.publicationTexte,
      });
    } else {
      console.log("error");
    }
  }),
};
