import styled from "@emotion/styled";
import { Helmet } from "react-helmet";
import { Breakpoints } from "../resources/types/Breakpoints";

export const Home: React.FC = () => {
  const StyledCatchPhrase = styled.h3`
    @media (max-width: ${Breakpoints.tablet - 1}px) {
      font-size: 1.1rem;
    }

    @media (max-width: ${Breakpoints.mobile - 1}px) {
      font-size: 1rem;

      margin-top: -20px;

      margin-left: auto;
      margin-right: auto;
    }

    /* display: none; */
    position: absolute;
    top: 158px;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    z-index: 10;
    font-size: 1.5rem;
    font-weight: 500;
  `;

  return (
    <>
      <StyledCatchPhrase>
        <Helmet>
          <title>Psychologue Frédérique HIRN</title>
          <meta
            name="description"
            content="Psychologue clinicienne, je suis spécialisée enfants et accompagnement à la parentalité.
J’accompagne également les professionnels de ce secteur. Mon approche est intégrative."
          />
          <meta
            name="keywords"
            content="psychologue, enfants, petite enfance, parentalité, attachement"
          />
        </Helmet>
        Spécialisée en Petite Enfance, Enfance & Parentalité
        {/* Parce que grandir n'est pas toujours facile
        <br />
        Et parce qu'accompagner les enfants à grandir ne l'est pas non plus... */}
      </StyledCatchPhrase>
    </>
  );
};
