import styled from "@emotion/styled";
import { Link, useLocation } from "react-router-dom";
import { Navigation } from "./Navigation";
import logo from "../resources/images/logo.svg";
import { Colors } from "../resources/types/Colors";
import { useEffect, useRef } from "react";
import { removeHomeClass } from "../resources/hooks/removeHomeClass";
import { Breakpoints } from "../resources/types/Breakpoints";

export const Header: React.FC = () => {
  const location = useLocation();
  const header = useRef<HTMLHeadElement>(null);
  const logoElm = useRef<HTMLPictureElement>(null);

  const elmArray = [header, logoElm];

  useEffect(() => {
    removeHomeClass(elmArray, location.pathname);
  });

  //style
  const StyledHeader = styled.header`
    width: 100%;
    box-sizing: border-box;

    h1 {
      display: none;
    }

    @media (max-width: ${Breakpoints.tablet - 1}px) {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    @media (max-width: ${Breakpoints.tablet - 1}px) {
      align-items: flex-start;
      margin-left: 30px;
      z-index: 800;
    }

    @media (max-width: ${Breakpoints.mobile - 1}px) {
      margin-left: 0px;
    }

    &.home {
      display: flex;
      align-items: center;
      padding-top: 45px;
      flex-direction: column;
      height: calc(100vh - 100px);

      h1 {
        order: -2;
        z-index: 2;

        display: flex;
        flex-direction: column;
        gap: 10px;

        background-color: ${Colors.green};
        color: white;
        padding: 15px;
        border-radius: 15px;

        text-align: center;
        font-size: 1.5rem;
        font-weight: bolder;
        width: 33%;
        max-width: 250px;

        @media (max-width: ${Breakpoints.mobile - 1}px) {
          width: 60%;
          max-width: unset;
          font-size: 1.2rem;
        }
      }
      h3 {
        order: -1;

        font-size: 1.5rem;
        font-weight: 700;
        text-align: center;
        margin-top: 30px;

        @media (max-width: ${Breakpoints.mobile - 1}px) {
          font-size: 1.2rem;
          margin-top: 0px;
        }
      }
    }
  `;

  const StyledLogo = styled.picture`
    a {
      z-index: 4;
      aspect-ratio: 1/1;

      picture {
        z-index: 4;
        img {
          z-index: 4;
          width: 100%;
          height: 100%;
        }
      }
    }

    &:not(.home) {
      @media (min-width: ${Breakpoints.tablet}px) {
        a {
          position: absolute;
          top: 35px;
          left: 100px;
          display: block;
          width: 120px;
        }
      }

      @media (max-width: ${Breakpoints.tablet - 1}px) {
        a {
          position: static;
          display: block;
          width: 80px;
          margin-top: 20px;
        }
      }

      @media (max-width: ${Breakpoints.mobile - 1}px) {
        a {
          position: static;
          display: block;
          width: 80px;
          margin-left: 30px;
          z-index: 800;
          margin-top: 20px;
        }
      }
    }

    &.home {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 100%;

      a {
        position: relative;
        top: 25px;
        z-index: 10;
        width: 250px;
        /* max-width: 350px;
        min-width: 200px; */
      }

      @media (max-width: ${Breakpoints.mobile - 1}px) {
        a {
          width: 100px;
          top: -20px;
        }
      }
    }
  `;

  return (
    <StyledHeader ref={header} className="home">
      <StyledLogo ref={logoElm} className="home">
        <Link to={"/"}>
          <img
            src={logo}
            alt="Logo du cabinet de psychologie de Frédérique Hirn"
          />
        </Link>
      </StyledLogo>
      <h1>
        <strong>Frédérique Hirn</strong>
        <span>Psychologue</span>
      </h1>

      {/* <h3>Enfance - Petite Enfance - Parentalité</h3> */}

      <Navigation />
    </StyledHeader>
  );
};
