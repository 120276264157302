import { createStore } from "easy-peasy";
import { AboutModel, aboutStore } from "./about";
import { AccesAndContactModel, accesAndContactStore } from "./accesAndContact";
import {
  ParentsAndChildsModel,
  parentsAndChildsStore,
} from "./parentsAndChilds";
import { ProfessionalsModel, professionalsStore } from "./professionals";

export interface IStore {
  about: AboutModel;
  parentsAndChilds: ParentsAndChildsModel;
  professionnals: ProfessionalsModel;
  accesAndContact: AccesAndContactModel;
}
export const store = createStore<IStore>({
  about: aboutStore,
  parentsAndChilds: parentsAndChildsStore,
  professionnals: professionalsStore,
  accesAndContact: accesAndContactStore,
});
