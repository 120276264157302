import { FormEvent, useRef } from "react";
import * as emailjs from "emailjs-com";
import styled from "@emotion/styled";
import { Colors } from "../resources/types/Colors";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const ContactForm: React.FC = () => {
  const name = useRef<HTMLInputElement>(null);
  const tel = useRef<HTMLInputElement>(null);
  const subject = useRef<HTMLInputElement>(null);
  const message = useRef<HTMLTextAreaElement>(null);

  const sendEmail = (e: FormEvent) => {
    e.preventDefault();

    emailjs
      .send(
        "service_foihc4p",
        "template_t1rgitu",
        {
          from_name: name.current?.value,
          reply_to: tel.current?.value,
          subject: subject.current?.value,
          message: message.current?.value,
        },
        "user_FppT3fuyxYTtInZ74XvQY"
      )
      .then((result) => {
        if (result.status === 200) {
          toast.success("Message envoyé avec succès !", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });

          name.current!.value = "";
          tel.current!.value = "";
          subject.current!.value = "";
          message.current!.value = "";
        } else {
          toast.error("Une erreur est survenue, veuillez réessayer.", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      })
      .catch((error) => {
        console.log(error);

        toast.error("Une erreur est survenue, veuillez réessayer.", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  const StyledForm = styled.form`
    background-color: ${Colors.green};
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    width: 90%;

    padding: 10px;

    input,
    textarea {
      width: 90%;
      margin: 0.5rem 0;
      padding: 0.5rem;
      border: 1px solid #ccc;
      border-radius: 4px;
      box-sizing: border-box;
    }

    label {
      font-size: 1.2rem;
      font-weight: bold;
      color: ${Colors.white};
    }

    textarea {
      height: 200px;
    }

    button {
      width: 75%;
      margin: 25px 0;
      padding: 0.5rem;
      background-color: ${Colors.red};
      color: white;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      transition: all 0.05s;
    }

    button:hover {
      opacity: 0.8;
      transition: all 0.05s;
    }
  `;

  return (
    <>
      <ToastContainer />
      <h2
        style={{
          textAlign: "center",
          fontSize: "2em",
          fontWeight: "bold",
          marginBottom: "30px",
        }}
      >
        Contactez moi directement !
      </h2>

      <StyledForm>
        <label>Nom / Prénom</label>
        <input
          type="text"
          name="name"
          ref={name}
          placeholder="Votre nom & prénom"
        />

        <label>Téléphone</label>
        <input
          type="phone"
          name="phone"
          ref={tel}
          placeholder="Votre numéro de téléphone..."
        />

        <label>Motif</label>
        <input
          type="text"
          name="motif"
          ref={subject}
          placeholder="Écrivez le motif de consultation ou demande..."
        />

        <label>Message & Disponibilité</label>
        <textarea
          name="message"
          ref={message}
          placeholder="Indiquez moi vos horaires de disponibilité afin que je puisse vous recontacter, ainsi que tout renseignements complémentaires..."
        />

        <button type="submit" onClick={sendEmail}>
          Envoyer
        </button>
      </StyledForm>
    </>
  );
};
