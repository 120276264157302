export const Colors = {
  red: "#ff7167",
  orange: "#ffaa56",
  green: "#70c5ba",
  lightRed: "#ffd6d0",
  lightOrange: "#ffe5d2",
  lightGreen: "#d3eae5",

  white: "#ffffff",
  black: "#000000",
  gray: "#a2a2a2",
};
